var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-bank"},[_c('Navgation',{attrs:{"bgColor":"#060B4C"}}),_c('div',{staticClass:"solution-bank-container"},[_vm._m(0),_c('section',{staticClass:"industry-problem"},[_c('div',{staticClass:"industry-problem-content",class:{
          'pre-hide-300': true,
          'move-bottom-300': _vm.isShowIndustryProblem,
        }},[_c('div',{staticClass:"industry-problem-title section-title"},[_vm._v("行业痛点")]),_c('div',{staticClass:"industry-problem-list"},_vm._l((_vm.industryProblemList),function(industryProblemItem){return _c('div',{key:industryProblemItem.label,staticClass:"industry-problem-list-item"},[_c('img',{staticClass:"industry-problem-list-item-img",attrs:{"src":industryProblemItem.img,"alt":""}}),_c('div',{staticClass:"industry-problem-list-item-label"},[_vm._v(" "+_vm._s(industryProblemItem.label)+" ")])])}),0)])]),_c('section',{staticStyle:{"background":"#060b4c"}},[_c('ProductProgram',{class:{
          'pre-hide-100': true,
          'move-bottom-100-1': _vm.isShowProductProgram,
        }})],1),_c('Cooperation',{attrs:{"type":"bank"}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner"},[_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-content-title"},[_vm._v("银行场景解决方案")]),_c('div',{staticClass:"banner-content-label"},[_vm._v(" 为小微企业提供一站式工商注册、掌上自助记账报税服务 助力银行打造小微企业数字化营销运营平台 ")])])])
}]

export { render, staticRenderFns }