<template>
  <div class="solution-bank">
    <Navgation bgColor="#060B4C" />
    <div class="solution-bank-container">
      <section class="banner">
        <div class="banner-content">
          <div class="banner-content-title">银行场景解决方案</div>
          <div class="banner-content-label">
            为小微企业提供一站式工商注册、掌上自助记账报税服务
            助力银行打造小微企业数字化营销运营平台
          </div>
        </div>
      </section>
      <section class="industry-problem">
        <div
          class="industry-problem-content"
          :class="{
            'pre-hide-300': true,
            'move-bottom-300': isShowIndustryProblem,
          }"
        >
          <div class="industry-problem-title section-title">行业痛点</div>
          <div class="industry-problem-list">
            <div
              class="industry-problem-list-item"
              v-for="industryProblemItem in industryProblemList"
              :key="industryProblemItem.label"
            >
              <img
                class="industry-problem-list-item-img"
                :src="industryProblemItem.img"
                alt=""
              />
              <div class="industry-problem-list-item-label">
                {{ industryProblemItem.label }}
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 产品方案 -->
      <section style="background: #060b4c">
        <ProductProgram
          :class="{
            'pre-hide-100': true,
            'move-bottom-100-1': isShowProductProgram,
          }"
        />
      </section>

      <!-- 合作方式 -->
      <Cooperation type="bank" />
    </div>
  </div>
</template>
<script>
import Navgation from '@/layouts/components/Navgation'
import ProductProgram from '../components/productProgram.vue'
import Cooperation from '../components/cooperation.vue'

export default {
  name: 'solutionBank',
  components: { Navgation, ProductProgram, Cooperation },
  inject: ['getPosition', 'scrollTo'],
  data() {
    return {
      industryProblemList: [
        {
          label: '获客成本不断提高',
          img: require('@/assets/images/solution/bank-industry-problem-icon.png'),
        },
        {
          label: '客户服务粘性低',
          img: require('@/assets/images/solution/bank-industry-problem-icon2.png'),
        },
        {
          label: '贷款前后管理、财税数据模型缺失',
          img: require('@/assets/images/solution/bank-industry-problem-icon3.png'),
        },
      ],
      isShowIndustryProblem: false,
      isShowProductProgram: false,
      noData: null,
    }
  },
  mounted() {
    this.isShowIndustryProblem = true
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 400) {
          this.isShowProductProgram = true
        }
      } else {
        if (scrollTop >= 650) {
          this.isShowProductProgram = true
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.solution-bank-container {
  margin-top: @navHeight;
  .section-title {
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #102a55;
  }
  .banner {
    height: 440px;
    background: url('../../../assets/images/solution/bank-banner-bg.png')
      no-repeat;
    background-size: 100% 440px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        margin-top: 144px;
        font-size: 54px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 75px;
        background: linear-gradient(
          270deg,
          #51ffc4 0%,
          #24eaff 40%,
          #1464ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &-label {
        margin-top: 12px;
        width: 500px;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }
  .industry-problem {
    height: 650px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .industry-problem-title {
        margin-top: 90px;
      }
      .industry-problem-list {
        margin-top: 114px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        &-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          &-img {
            width: 136px;
            height: 136px;
          }
          &-label {
            margin-top: 14px;
            line-height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #102a55;
          }
        }
      }
    }
  }
  .pre-hide-300 {
    transform: translateY(300px);
    opacity: 0;
  }
  .move-bottom-300 {
    animation: move-bottom-300 1s 0.2s forwards ease-in-out;
  }
  .pre-hide-100 {
    transform: translateY(100px);
    opacity: 0;
  }
  .move-bottom-100 {
    animation: move-bottom-100 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-100-1 {
    animation: move-bottom-100-1 1s 0.2s forwards ease-in-out;
  }
  @keyframes move-bottom-300 {
    0% {
      transform: translateY(300px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-100 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-100-1 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
</style>
